.navbar {
    background: #fff;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
  }
  
  .container {
    z-index: 1;
    width: 90%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
  }
  
  .navbar-logo {
    color: #000000;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .navbar-icon {
    margin-right: 0.5rem;

  }

  .nav-icon-down {
    margin-left: 2px;
   
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: end;
  }
  
  .nav-item {
    height: 80px;
    border-bottom: 2px solid transparent;

 
  }
  
  
  .nav-item:hover {
    border-bottom: 3px solid #0099d9;
  }
  
  .nav-links {
    color:  #000000;
    
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .fa-bars {
    color: #000000;
  }
  
  .menu-icon {
    display: none;
    color:#232f3e;
  }
  .nav-drop-con{
  
    height: 80px;
    padding: 0.5rem 1rem;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    margin-top: 30%;
 
  }
  .nav-item-drop{
   

    justify-content: center;


  }
  .nav-drop-con:hover{

  }
  .nav-drop-down{
  
    display: none;
    position: absolute;
    background-color: #fff;
    top: 80px;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

.nav-item-drop:hover .nav-drop-down {display: block; }

.nav-drop-down-link{
  color: rgb(70, 70, 70);
  padding: 3px 4px;
  text-decoration: none;
  display: block;
  font-size: smaller;
  text-align: left;
  border-left: 2px solid transparent;
  


}
.nav-drop-down-link:hover {
  color: #000000;
  border-left: 2px solid #0099d9;

}

  

  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background-color: #fff;
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
 
    }
  
    .nav-links:hover {

      transform: scale(1.2);
      transition: all 0.3s ease;
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
      width: 100%;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 120px;
  
    }
  }