.footer-background{
    background: #242424;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: #fff;
    margin-bottom: 10px;
    padding-top: 5px;
    margin-top: 10px;
}

.footer-container {
    /*background: 
    #ffffea
    #fde7cc; tan
    #ffd8d4 pinkish
    #83d0ff light blue
    #66b0f0

    background-image: linear-gradient( rgb(252, 237, 245), #FDD8CB);
    background: #252525;
    */
    color: #fff;
    padding: 15px;
  

    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    
   
}
.footer-title{
   
    font-size: 20px;


}
.footer-container-contact{

 
    
}
.footer-container ul{
    list-style-type: none;
}
.footer-container li{
    margin: 5px;
    font-size: 14px;
    border-bottom: 2px solid transparent;

}
.footer-container li:hover{
    border-bottom:  2px solid #fff;

}
.footer-contact{
    font-size: 15px;
    margin-top: 8px;
}
.social-media-icon-footer{
    font-size: 30px;
    color: #fff;
    margin: 5px;
    
}
.footer-bottom{
    width: 80%;
    margin: auto;
    margin-bottom: 10px;
}
.footer-desc{
    margin-top: 10px;

    padding: 5px;
}
.footer-link{
    text-decoration: none;
    color: #fff;
}

@media screen and (max-width: 960px) {
    .footer-container {
        width: 90%;
    }
}